/**
 * Link
 * @path: components/link
 */

.c-link{ 
    text-decoration:none; 
    border-bottom:1px solid get-color(primary);
    transition: border .15s ease-in-out;
    display:inline !important;
    &.is-active{ 
        border-bottom:0; text-decoration:none; 
        &::after{ 
            margin-left:.5rem;
            content:'•'; 
        }
    }
    &.is-inactive{ border-bottom:0; color:scale-color( get-color(primary), $lightness: 40%, $saturation: -42% ); }
}

.is-desktop{
    .c-link:hover{ 
        text-decoration:none;
        border-bottom:1px solid transparent;
    } 
}