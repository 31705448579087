/**
 * Utility classes to specific values onto elements.
 * @path: utilities/spacing
 */

// Loop through '$spacing-sizes' list (settings/custom)
@each $size, $spacing in $v-spacing-sizes {
    // Create breakpoint for every '$size' defined in the list
    @include breakpoint($size) {
        .u-padding-vertical{ padding-top:rem-calc($spacing) !important; padding-bottom:rem-calc($spacing) !important; }
        .u-padding-top{ padding-top:rem-calc($spacing) !important; }
        .u-padding-bottom{ padding-bottom:rem-calc($spacing) !important; }
        .u-margin-vertical{ margin-top:rem-calc($spacing) !important; margin-bottom:rem-calc($spacing) !important; }
        .u-margin-top{ margin-top:rem-calc($spacing) !important; }
        .u-margin-bottom{ margin-bottom:rem-calc($spacing) !important; }
    }
}

// Create spacing classes that apply from the breakpoint they're named after
@include -zf-each-breakpoint{
    .u-margin-top-#{$-zf-size}{ margin-top:rem-calc( get-v-spacing(small) ); }
    .u-margin-bottom-#{$-zf-size}{ margin-bottom:rem-calc( get-v-spacing(small) ); }
    .u-margin-vertical-#{$-zf-size}{ margin-top:rem-calc( get-v-spacing(small) ); margin-bottom:rem-calc( get-v-spacing(small) ); }
    .u-padding-top-#{$-zf-size}{ padding-top:rem-calc( get-v-spacing(small) ); }
    .u-padding-bottom-#{$-zf-size}{ padding-bottom:rem-calc( get-v-spacing(small) ); }
    .u-padding-vertical-#{$-zf-size}{ padding-top:rem-calc( get-v-spacing(small) ); padding-bottom:rem-calc( get-v-spacing(small) ); }

    .u-no-margin-bottom-#{$-zf-size}{ margin-bottom:0 !important; }
    .u-no-padding-bottom-#{$-zf-size}{ padding-bottom:0 !important; }
}

.u-no-margin-top{ margin-top:0 !important; }
.u-no-margin-bottom{ margin-bottom:0 !important; }

.u-gutter{ padding-left:get-gutter(small) !important; padding-right:get-gutter(small) !important; }
.u-gutter-left{ padding-left:get-gutter(small) !important; }
