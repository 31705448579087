/**
 * Table
 * @path: components/table
 */

.c-table{
    width:100%; 
    border-collapse: collapse;
}
.c-table-head, .c-table-cell{ 
    padding-top:1rem; padding-bottom:1rem; 
    border-bottom:.1rem solid scale-color( get-color(primary), $lightness: 55%, $saturation: -50%);
}
.c-table-head{
    cursor:pointer; font-weight:$global-weight-normal; text-align:left; font-family:$body-font-family;
    &[class*=" sorting"]::after, 
    &[class^="sorting"]::after{ 
        margin-left:.5rem;
        @extend %icons; 
    }
    &.sorting_asc::after{ @extend .icon-sort-asc::before; }
    &.sorting_desc::after{ @extend .icon-sort-desc::before; }
}
.c-table-head-title{ border-bottom:.1rem solid $primary-color; }

.dataTables_empty{ display:none; }

/**
 * Custom alignment of the table view
 */

/* 640+ */
@include breakpoint(medium) {

    .c-table-head{
        &:nth-child(1){ width:52.5% !important; }
        &:nth-child(2){ width:26.5% !important; }
        &:nth-child(3){ width:21% !important; }
    }

}

/* 1024+ */
@include breakpoint(large) {

    .c-table-head{
        &:nth-child(1){ width:60.5% !important; }
        &:nth-child(2){ width:17.5% !important; }
        &:nth-child(3){ width:22% !important; }
    }

}

/* 1200+ */
@include breakpoint(xlarge) {

    .c-table-head{
        &:nth-child(1){ width:61% !important; }
        &:nth-child(2){ width:20.5% !important; }
        &:nth-child(3){ width:17% !important; }
    }

}