/**
 * Paragraph
 * @path: components/paragraph
 */

$paragraph-offset: 10;

.c-paragraph{
    @include font-family(medium);
    @include flex-grid-column(12);
    @include grid-column();

    /* Offset for paragraph */
    @include -zf-each-breakpoint {
        @for $i from 1 through $grid-column-count {
            .#{$-zf-size}-offset-#{$i} {
                @include grid-column-offset($i);
            }
        }
    }
}

/* 1024+ */
@include breakpoint(large) {
    .c-paragraph{
        @include flex-grid-column(10);
    }
}

/* 1200+ */
@include breakpoint(xlarge) {

    .c-paragraph{
        @include flex-grid-column(8);
    }

}