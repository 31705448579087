/**
 * Header
 * @path: components/header
 */

.c-header{ 
    padding-top:2rem;
    position:fixed; z-index:1000; top:0; top:0; right:0; left:0;
    transition:transform .25s ease-in-out, background-color .3s ease-in-out;
    &.is-fixed{ 
        height:auto; padding-bottom:1.5rem;
        background-color:$body-background;
        .c-logo-payoff{ 
            height:0;
            opacity:0; 
        }
    }
    &.is-up{ transform:translateY(-100%); }
    &.is-down{ transform:translateY(0); }
}

.o-overlay{
    .c-header{ position:static; }
}