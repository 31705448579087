/**
 * Typography
 * @path: utilities/font-size
 */

/**
 * Font-sizes:
 * u-font-size-small
 * u-font-size-large
 */
// Loop through '$body-styles' list (settings/custom) 
@each $size, $styles in $body-styles {
    // Create the breakpoints for the different sizes defined in the $body-styles map
    @include breakpoint($size) {
        // Loop through the styles per breakpoint
        @each $class, $style-defs in $styles {
            // Create the classes and set the font-size
            #{$class} {
                font-size:rem-calc( map-get( $style-defs, font-size ) ); 
                line-height:map-get( $style-defs, line-height );
            }
        }
    }
}

/**
 * Font weights
 */
.u-font-family-normal{ @include font-family(regular); }
.u-font-family-bold{ @include font-family(medium); }

.u-no-bullet{
    margin:0;
    list-style-type:none;
}

/**
 * Text transforms
 */
.u-text-ellipsis{ 
    max-width:13rem;
    white-space:nowrap; text-overflow:ellipsis;
    overflow:hidden;
}

/* 640+ */
@include breakpoint(medium) {
    .u-text-ellipsis{ 
        max-width:none; 
        white-space:normal; text-overflow:clip;
        overflow:visible;
    }
}