/**
* Form components
* @parth:components/form
*/

// Mixin
// Calculate height using u-font-size-small declarations in $body-styles map
@mixin input-properties {
    @each $breakpoint, $styles in $body-styles {
        @include breakpoint($breakpoint) {
            @each $class, $style-defs in $styles {
                @if $class == '.u-font-size-small' {
                    height:rem-calc( map-get( $style-defs, font-size ) ) + $form-spacing * 1.5;
                    font-size:rem-calc( map-get( $style-defs, font-size ) );
                }
            }
        }
    }
}

/* Basic input style */
#{text-inputs()},
textarea {
    @include input-properties;
    width:100%; margin:0 0 $form-spacing; padding:( $form-spacing / 2 ) $form-spacing; box-sizing:border-box;
    border:$input-border; border-radius:$input-radius; background-color:$input-background; box-shadow:$input-shadow; 
    font-family:$input-font-family; font-weight:$input-font-weight; color:$input-color;
    appearance:none;
    display:block;
    @if has-value($input-transition) {
        transition:$input-transition;
    }

    // Focus state
    &:focus {
        border:$input-border-focus; background-color:$input-background-focus; box-shadow:$input-shadow-focus;
        outline:none;
        @if has-value($input-transition) {
            transition:$input-transition;
        }
    }
}

// Text areas
textarea {
    max-width:100%;
    &[rows] { height:auto; }
}

input,
textarea {
    // Placeholder text
    &::placeholder { color:$input-placeholder-color; }

    // Disabled/readonly state
    &:disabled,
    &[readonly] {
        background-color:$input-background-disabled;
        cursor:$input-cursor-disabled;
    }
}

// Reset styles on button-like inputs
[type='submit'],
[type='button'] {
    appearance:none;
    border-radius:$form-button-radius;
}

// Reset Normalize setting content-box to search elements
input[type='search'] { box-sizing:border-box; }

// Number input styles
[type='number'] {
    @if not $input-number-spinners {
        -moz-appearance:textfield;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance:none;
            margin:0;
        }
    }
}

/* Input groups */
.c-input-group{
    width:100%; margin-bottom:$form-spacing;
    display:flex;
    @if $global-flexbox {
        align-items:stretch;
    }
    > :first-child { border-radius:$input-radius 0 0 $input-radius; }
    > :last-child {
        > * { border-radius:0 $input-radius $input-radius 0; }
    }
}

%c-input-group-child {
    margin:0;
    white-space:nowrap;
}

.c-input-group-label {
    @extend %c-input-group-child;
    padding:0 $input-prefix-padding;
    color:$input-prefix-color; text-align:center; white-space:nowrap;
    border:$input-prefix-border; background-color:$input-prefix-background;
    display:flex; flex:0 0 auto; align-items:center;
    @if has-value($input-prefix-border) {
        &:first-child { border-#{$global-right}:0; }
        &:last-child { border-#{$global-left}:0; }
    }
}

.c-input-group-field {
    @extend %c-input-group-child;
    min-width:0; height:auto;
    border-radius:0;
    flex:1 1 0px; 
}

.c-input-group-button {
    @extend %c-input-group-child;
    padding-top:0; padding-bottom:0;
    text-align:center;
    flex:0 0 auto;
    a,
    input,
    button,
    label {
        @extend %c-input-group-child;
        @include input-properties;
        padding-top:0; padding-bottom:0;
    }
}