/**
 * Mixins
 * @path: tools/mixins
 */

/** 
 * Alert boxes (from Foundation 5)
 */
// Default alert base.
@mixin alert-base {
    border-style: $alert-border-style;
    border-width: $alert-border-width;
    display: block;
    font-weight: $alert-font-weight;
    margin-bottom: $alert-bottom-margin;
    position: relative;
    padding: $alert-padding-top $alert-padding-opposite-direction $alert-padding-bottom $alert-padding-default-float;
    font-size: $alert-font-size;
    transition:opacity $alert-transition-speed $alert-transition-ease;
}

// Add alert styles
@mixin alert-style($bg:$primary-color) {
    // Lightness percentage of the background color.
    $bg-lightness: lightness($bg);

    background-color: $bg;
    border-color: scale-color($bg, $lightness: $alert-function-factor);

    // Text color based on the background color.
    @if $bg-lightness > 70% { color: $alert-font-color-alt; }
    @else                   { color: $alert-font-color; }
}

// We use this to create the close button.
@mixin alert-close {
    font-size: $alert-close-font-size;
    padding: $alert-close-padding;
    line-height: .9;
    position: absolute;
    top: $alert-close-top;
    margin-top: -($alert-close-font-size / 2);
    right: $alert-close-position;
    color: $alert-close-color;
    opacity: $alert-close-opacity;
    background: $alert-close-background;
    &:hover,
    &:focus { opacity: $alert-close-opacity-hover; }
}

// Single mixin to create the alert
@mixin alert($bg:$primary-color, $radius:false) {
    @include alert-base;
    @include alert-style($bg);
}

/**
 * Font weights
 */
@mixin font-family($weight) {
    @if $weight == 'regular' {
        font-family: $body-font-family;
    }@elseif $weight == 'medium' {
        font-family: $header-font-family;
    }
}

/**
 * Block grid
 */
@mixin block-grid(
    $per-row:false,
    $spacing:get-gutter(small),
    $include-spacing:true,
    $base-style:true,
    $flex-box:true) {

    @if $base-style {
        @include clearfix;
        // Add gutter when reaching large breakpoint
        @include breakpoint(large){
            margin-left:get-gutter(small); margin-right:get-gutter(small);
        }
        width:100%; padding: 0;
        @if $flex-box == true {
            flex-flow: row wrap;
            display: flex;
        }

        .o-column{
            @include grid-column-v-spacing;
            height: auto;
        }
    }

    @if $per-row {
        .o-column {
            @if $include-spacing {
                padding-left:($spacing/2); padding-right:($spacing/2);
            }
            @if $flex-box == true {
                flex-grow:0;
                flex-shrink:1;
                width:100%/$per-row;
            }
            list-style: none;

            &:nth-of-type(1n) { clear: none; }
            &:nth-of-type(#{$per-row}n+1) { clear: both; }
            @include block-grid-aligned($per-row, $spacing);
        }
    }
}

// Generate presentational markup for block grid.
@mixin block-grid-html-classes($breakpoint,$include-spacing) {
    @for $i from 1 through 4 {
        .o-block-grid-#{$breakpoint}-#{($i)} {
            @include block-grid($i, get-gutter(small), $include-spacing, false, true);
        }
    }
}

// Make sure the space between the grid items is the same size as the gutter of the website
@mixin block-grid-aligned($per-row, $spacing) {
    @for $i from 1 through 4 {
        @if $per-row >= $i {
            $grid-column: '+' + $i;
            @if $per-row == $i {
                $grid-column: '';
            }
            &:nth-of-type(#{$per-row}n#{unquote($grid-column)}) {
                padding-left: ($spacing - (($spacing / $per-row) * ($per-row - ($i - 1))));
                padding-right: ($spacing - (($spacing / $per-row) * $i));
            }
        }
    }
}

@mixin grid-column-v-spacing (
    $padding: auto,
    $paddings: $v-spacing-sizes
) {
    @include -zf-breakpoint-value($padding, $paddings) {
        $padding-value: rem-calc($-zf-bp-value);
        padding-top: $padding-value; padding-bottom: $padding-value;
    }
}
