/**
 * Wrappers
 * @path: objects/wrappers
 */

.o-header-wrapper{ height:5.5rem; }

.o-vacancy-wrapper{
    .o-media-heading{ 
        @include font-family(regular);
        font-size:1.5rem; line-height:1.3;
    }
}

/* 640+ */
@include breakpoint(medium) {
    .o-vacancy-wrapper{
        .o-media-heading{ 
            @include font-family(medium);
            font-size:3rem; line-height:1.2;
        }
    }
}

/* 1024+ */
@include breakpoint(large) {
    .o-header-wrapper{ height:7rem; }

    .o-vacancy-wrapper{
        .o-media-heading{ 
            @include font-family(medium);
            font-size:4rem; line-height:1.1;
        }
    }
}