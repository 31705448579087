/**
 *  Custom Settings
 *  @path: settings/custom
 *  ----------------------
 *  
 *  Table of Contents:
 *
 *   1. Font-family
 *   2. Font-size
 *   3. Grid
 *   4. Vertical spacing
 */

// 1. Font-family
// ------------
@font-face {
    font-family: 'SGNextBSK-Regular';
    src: url('../fonts/sg-next-bsk/SGNextBSK-Regular.eot');
    src: url('../fonts/sg-next-bsk/SGNextBSK-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/sg-next-bsk/SGNextBSK-Regular.woff2') format('woff2'),
        url('../fonts/sg-next-bsk/SGNextBSK-Regular.woff') format('woff'),
        url('../fonts/sg-next-bsk/SGNextBSK-Regular.ttf') format('truetype');
    font-weight:normal;
}

@font-face {
    font-family: 'SGNextBSK-Medium';
    src: url('../fonts/sg-next-bsk/SGNextBSK-Medium.eot');
    src: url('../fonts/sg-next-bsk/SGNextBSK-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/sg-next-bsk/SGNextBSK-Medium.woff2') format('woff2'),
        url('../fonts/sg-next-bsk/SGNextBSK-Medium.woff') format('woff'),
        url('../fonts/sg-next-bsk/SGNextBSK-Medium.ttf') format('truetype');
    font-weight:500;
}

// 2. Font-size
// ------------
$body-styles: (
    small: (
        '.u-font-size-large': ('font-size': 20, 'line-height': 1.3),
        '.u-font-size-small': ('font-size': 15, 'line-height': 1.3),
    ),
    medium: (
        '.u-font-size-large': ('font-size': 30, 'line-height': 1.2),
        '.u-font-size-small': ('font-size': 17, 'line-height': 1.3),
    ),
    large: (
        '.u-font-size-large': ('font-size': 40, 'line-height': 1.1),
        '.u-font-size-small': ('font-size': 20, 'line-height': 1.3),
    )
);

// 3. Grid
// ------------
$o-grid: (
    small: (
        '.o-grid-small' : ( grid-size : 8, collapse: false ),
        '.o-grid-medium' : ( grid-size : 8, collapse: false ),
        '.o-grid-large' : ( grid-size : 12, collapse: true ),
        '.o-grid-xlarge' : ( grid-size : 12, collapse: true ),
    ),
    medium: (
        '.o-grid-small' : ( grid-size : 5, collapse: false ), 
        '.o-grid-medium' : ( grid-size : 6, collapse: false ),
        '.o-grid-large' : ( grid-size : 12, collapse: false ),
        '.o-grid-xlarge' : ( grid-size : 12, collapse: false ),
    ),
    large: (
        '.o-grid-small' : ( grid-size : 4, collapse: false ), 
        '.o-grid-medium' : ( grid-size : 6, collapse: false ),
        '.o-grid-large' : ( grid-size : 9, collapse: false ),
        '.o-grid-xlarge' : ( grid-size : 12, collapse: false ),
    ),
    xlarge: (
        '.o-grid-small' : ( collapse: false ),
        '.o-grid-medium' : ( collapse: false ),
        '.o-grid-large' : ( collapse: false ),
        '.o-grid-xlarge' : ( grid-size : 12, collapse: false ),
    ),
    xxlarge: (
        '.o-grid-small' : ( collapse: false ),
        '.o-grid-medium' : ( collapse: false ),
        '.o-grid-large' : ( collapse: false ),
        '.o-grid-xlarge' : ( grid-size : 12, collapse: false ),
    ),
);

// 4. Vertical spacing
// ------------
$v-spacing-sizes: (
    small: 25,
    medium: 40,
    large: 55,
    xlarge : 70
);