/**
* Lightcase - jQuery Plugin
* The smart and flexible Lightbox Plugin.
*
* @author       Cornel Boppart <cornel@bopp-art.com>
* @copyright    Author
*
* @version      2.4.0 (09/04/2017)
*/

$lightcase-default: (
    'font-path': '../fonts/icons/', // Path relative to the compiled css file

    'breakpoint': 640px,

    'overlay-color': scale-color( #517C91, $lightness: 75%, $saturation: -60%),

    'case-background': transparent,
    'case-background-media': transparent,

    'icon-font-size': 2.5rem,
    'icon-color': #517C91,
    'icon-color-hover': scale-color(#517C91, $lightness: -14%),

    'title-font-size': 1.7rem,
    'title-color': #aaaaaa,

    'caption-font-size': 1.3rem,
    'caption-color': #aaaaaa,

    'sequenceInfo-font-size': 1.1rem,
    'sequenceInfo-color': #aaaaaa,

    'text-shadow': none,
    'text-color-viewport-small': #517C91,
    'text-color-viewport-large': #517C91
);

$lightcase-custom: ()!default;
$lightcase-settings: map-merge($lightcase-default, $lightcase-custom);

@font-face {
    font-family: 'lightcase';
    src: url('#{map-get($lightcase-settings, font-path)}dezwartehond.eot?55356177');
    src: url('#{map-get($lightcase-settings, font-path)}dezwartehond.eot?55356177#iefix') format('embedded-opentype'),
       url('#{map-get($lightcase-settings, font-path)}dezwartehond.woff?55356177') format('woff'),
       url('#{map-get($lightcase-settings, font-path)}dezwartehond.ttf?55356177') format('truetype'),
       url('#{map-get($lightcase-settings, font-path)}dezwartehond.svg?55356177#lightcase') format('svg');
    font-weight: normal;
    font-style: normal;
}

/**
 * Mixin providing icon defaults to be used on top of font-lightcase.
 *
 * Example usage:
 * @include icon(#e9e9e9)
 */
 @mixin icon($color: #fff) {
    position: fixed;
    z-index: 9999;

    width: 1.123em;
    height: auto;

    font-size: #{map-get($lightcase-settings, 'icon-font-size')};
    line-height: 1;

    text-align: center;
    &, &:focus {
        text-decoration: none;
        color: $color;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        -webkit-transition: color, opacity, ease-in-out 0.25s;
        -moz-transition: color, opacity, ease-in-out 0.25s;
        -o-transition: color, opacity, ease-in-out 0.25s;
        transition: color, opacity, ease-in-out 0.25s;
    }

    & > span {
        display: inline-block;
        text-indent: -9999px;
    }
}

/**
 * Mixin providing icon defaults including a hover status to be used
 * on top of font-lightcase.
 *
 * Example usage:
 * @include icon-hover(#e9e9e9, #fff)
 */
 @mixin icon-hover($color: #fff, $color-hover: #f9f9f9) {
    @include icon($color);

    // Default hover status
    &:hover {
        color: $color-hover;
    }
}

/**
 * Provides natural content overflow behavior and scrolling support
 * even so for touch devices.
 *
 * Example usage:
 * @include overflow()
 */
@mixin overflow() {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

/**
 * Neutralizes/resets dimensions including width, height, position as well as margins,
 * paddings and styles. Used to enforce a neutral and unstyled look and behavoir!
 *
 * Example usage:
 * @include clear(true)
 *
 * @param boolean $important
 */
@mixin clear($important) {
    @if $important {
        $important: !important;
    }

    position: relative $important;

    top: auto $important;
    left: auto $important;

    width: auto $important;
    height: auto $important;

    margin: 0 $important;
    padding: 0 $important;

    border: none $important;
    background: none $important;
}

[class*='lightcase-icon-']:before {
    font-family: 'lightcase', sans-serif;
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Uncomment for 3D effect */
}

/* Codes */
// .lightcase-icon-play:before { content: '\e800'; }
// .lightcase-icon-pause:before { content: '\e801'; }
.lightcase-icon-close:before { content: '\66'; }
.lightcase-icon-prev:before { content: '\6a'; }
.lightcase-icon-next:before { content: '\6b'; }
// .lightcase-icon-spin:before { content: '\e805'; }

// Spin animation
@-webkit-keyframes lightcase-spin {

    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        transform: rotate(359deg);
    }

}

@-moz-keyframes lightcase-spin {

    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        transform: rotate(359deg);
    }

}

@-o-keyframes lightcase-spin {

    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        transform: rotate(359deg);
    }

}

@-ms-keyframes lightcase-spin {

    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        transform: rotate(359deg);
    }

}

@keyframes lightcase-spin {

    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        transform: rotate(359deg);
    }

}

#lightcase-case {
    display: none;

    position: fixed;
    z-index: 2002;
    top: 50%;
    left: 50%;

    font-family: arial, sans-serif;
    font-size: 13px;
    line-height: 1.5;
    text-align: left;

    // Only for type: inline, ajax
    html[data-lc-type=inline] &,
    html[data-lc-type=ajax] & {
        @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
            position: fixed !important;
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            margin: 0 !important;
            padding: 55px 0 70px 0;
            width: 100% !important;
            height: 100% !important;
            overflow: auto !important;
        }
    }
}

#lightcase-content {
    // Not type: error
    html:not([data-lc-type=error]) & {
        @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
            position: relative;
            z-index: 1;

            background-color: #{map-get($lightcase-settings, 'case-background')};
            -webkit-backface-visibility: hidden;
        }
    }

    // Type: image + video
    html[data-lc-type=image] &,
    html[data-lc-type=video] & {
        @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
            background-color: #{map-get($lightcase-settings, 'case-background-media')};
        }
    }

    // Only for type: inline, ajax, error
    html[data-lc-type=inline] &,
    html[data-lc-type=ajax] &,
    html[data-lc-type=error] & {
        @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
            @include clear(true);
        }

        .lightcase-contentInner {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -o-box-sizing: border-box;
            box-sizing: border-box;

            @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
                padding: 15px;

                &, & > * {
                    width: 100% !important;
                    max-width: none !important;
                }

                // Set auto height to each element except iframes, because of that
                // those cannot have a natural/native height and it must be especially defined.
                & > *:not(iframe) {
                    height: auto !important;
                    max-height: none !important;
                }
            }
        }
    }

    .lightcase-contentInner {
        @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
            // For mobile (touch) devices
            html.lightcase-isMobileDevice[data-lc-type=iframe] & iframe {
                @include overflow();
            }
            // Type: image + video
            html[data-lc-type=image] &,
            html[data-lc-type=video] & {
                @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
                    line-height: 0.75;
                }
            }
        }

        html[data-lc-type=image] & {
            position: relative;
            overflow: hidden !important;
        }

        // Note: This is a very special wrap only added for few
        // types of content, type 'inline' and 'ajax'.
        .lightcase-inlineWrap {
            // Only for type: inline, ajax error
            html[data-lc-type=inline] &,
            html[data-lc-type=ajax] &,
            html[data-lc-type=error] & {
                @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
                    @include clear(true);
                }
            }

            // Not type: error
            html:not([data-lc-type=error]) & {
                @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
                    padding: 30px;

                    overflow: auto;

                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    -o-box-sizing: border-box;
                    box-sizing: border-box;
                }
            }
        }
    }

    // Content - Extend with further selectors and colors in case you have
    // elements like <ul>, <li>, <span> or other as part of content within lightcase.
    h1, h2, h3, h4, h5, h6, p {
        @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
            color: #{map-get($lightcase-settings, 'text-color-viewport-small')};
        }

        @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
            color: #{map-get($lightcase-settings, 'text-color-viewport-large')};
        }
    }

}

#lightcase-case {

    p.lightcase-error {
        margin: 0;
        font-size: 17px;
        text-align: center;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        color: #aaa;

        @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
            padding: 30px 0;
        }

        @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
            padding: 0;
        }
    }

}

// Styles and overrides for all elements out of the lightcase scope
.lightcase-open {

    body {
        overflow: hidden;

        // For mobile (touch) devices
        .lightcase-isMobileDevice & {
            max-width: 100%;
            max-height: 100%;
        }
    }

}

#lightcase-info {
    // You can change to position "static" or "relative" instead, but consider
    // that this height will be integrated and calculated
    // to the maximum height additionally to the image height.
    position: absolute;

    padding-top: 15px;

    #lightcase-title,
    #lightcase-caption {
        margin: 0;
        padding: 0;

        line-height: 1.5;
        font-weight: normal;
        text-overflow: ellipsis;
    }

    #lightcase-title {
        font-size: #{map-get($lightcase-settings, 'title-font-size')};
        color: #{map-get($lightcase-settings, 'title-color')};

        @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
            position: fixed;
            top: 10px;
            left: 0;
            max-width: 87.5%;
            padding: 5px 15px;
            background: #{map-get($lightcase-settings, 'overlay-color')};
        }
    }

    #lightcase-caption {
        clear: both;
        font-size: #{map-get($lightcase-settings, 'caption-font-size')};
        color: #{map-get($lightcase-settings, 'caption-color')};
    }

    #lightcase-sequenceInfo {
        font-size: #{map-get($lightcase-settings, 'sequenceInfo-font-size')};
        color: #{map-get($lightcase-settings, 'sequenceInfo-color')};
    }

    @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
        .lightcase-fullScreenMode & {
            padding-left: 15px;
            padding-right: 15px;
        }

        // Not type: image, video, flash or error
        html:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) & {
            position: static;
        }
    }
}

#lightcase-loading {
    @include icon(#517C91);

    position: fixed;
    z-index: 2001;
    top: 50%;
    left: 50%;

    margin-top: -0.5em;
    margin-left: -0.5em;

    opacity: 1;

    font-size: 32px;

    // Fixes the icon wobble issue in firefox
    -moz-transform-origin: 50% 53%;

    -webkit-animation: lightcase-spin 0.5s infinite linear;
    -moz-animation: lightcase-spin 0.5s infinite linear;
    -o-animation: lightcase-spin 0.5s infinite linear;
    animation: lightcase-spin 0.5s infinite linear;
}

// Generic styles for all navigation links
a[class*='lightcase-icon-'] {
    @include icon-hover(#{map-get($lightcase-settings, 'icon-color')}, #{map-get($lightcase-settings, 'icon-color-hover')});

    outline: none;
    cursor: pointer;

    // Hover status for mobile (touch) devices
    .lightcase-isMobileDevice & {
        &:hover {
            color: #aaa;
        }
    }

    // Close button
    &.lightcase-icon-close {
        position: fixed;
        top: 15px;
        right: 15px;
        bottom: auto;
        margin: 0;
        opacity: 0;
        outline: none;
    }

    // Prev button
    &.lightcase-icon-prev {
        left: 15px;
    }

    // Next button
    &.lightcase-icon-next {
        right: 15px;
    }

    // Play/Pause button
    &.lightcase-icon-pause,
    &.lightcase-icon-play {
        left: 50%;
        margin-left: -0.5em;

        @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
            opacity: 0;
        }
    }

    @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
        bottom: 15px;
        font-size: 24px;
    }

    @media screen and (min-width: #{map-get($lightcase-settings, 'breakpoint')}) {
        bottom: 50%;
        margin-bottom: -0.5em;

        &:hover,
        #lightcase-case:hover ~ & {
            opacity: 1;
        }
    }
}

#lightcase-overlay {
    display: none;
    width: 100%;
    min-height: 100%;

    position: fixed;
    z-index: 2000;
    top: -9999px;
    bottom: -9999px;
    left: 0;

    background: #{map-get($lightcase-settings, 'overlay-color')};

    @media screen and (max-width: #{map-get($lightcase-settings, 'breakpoint')-1}) {
        opacity: 1 !important;
    }
}