/**
 * Quote
 * @path: components/quote
 */

.c-quote{
    margin-bottom:2.5rem;
    .c-quote-body{
        @include font-family(medium);
        &::before{ content:''; }
        &::after{ content:''; }
    }
}

/* 640+ */
@include breakpoint(medium) {
    .c-quote{ margin-bottom:0; }
}