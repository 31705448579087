/**
 * Foundation for Sites by ZURB
 * Version 6.1.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Disable warnings in terminal
$show-warnings: false;

// Custom settings
@import 'settings/custom'; 

// Import settings_frontend.scss. For overwriting foundation variables
@import 'settings/foundation';

// Import all Foundation components
@import '../../../../node_modules/foundation-sites/scss/foundation.scss';


// Include the right mixins
@if not $global-flexbox {
    @include foundation-grid;
}
@else {
    @include foundation-flex-grid;
}
@include foundation-global-styles;
@include foundation-typography;
@include foundation-button;
// @include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
// @include foundation-close-button;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-flex-video;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-menu;
@include foundation-menu-icon
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;

@if $global-flexbox {
    @include foundation-flex-classes;
}

// Tools
@import 'tools/mixins';
@import 'tools/functions';

// Generic
@import 'generic/appearance';
@import 'generic/normalize';

// Elements
@import 'elements/page';

// Objects
@import 'objects/grid';
@import 'objects/media';
@import 'objects/overlay';
@import 'objects/wrappers';

// Components
@import 'components/alert';
@import 'components/animate';
@import 'components/filter';
@import 'components/form';
@import 'components/header';
@import 'components/icons';
@import 'components/iframe';
@import 'components/lightcase';
@import 'components/link'; 
@import 'components/list';
@import 'components/loader';
@import 'components/logo';
@import 'components/menu';
@import 'components/paragraph';
@import 'components/quote';
@import 'components/switch';
@import 'components/slider';
@import 'components/table';

// Utilities
@import 'utilities/color';
@import 'utilities/display';
@import 'utilities/height';
@import 'utilities/spacing';
@import 'utilities/typography';
