/**
 * Animate
 * @path: components/animate
 */

.c-animate{
    opacity:1;
    transition:opacity .8s ease-in-out;
    &.is-invisible{ opacity:0; }
}
