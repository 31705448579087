 /**
 * Media objects
 * @path:objects/media
 */

.o-media{ 
    display:flex; 
    &-top{ align-items:flex-start; }
    &-center{ align-items:center; }
    &-bottom{ align-items:flex-end; }
    &-left{ jusitfy-content:flex-start; }
    &-center{ jusitfy-content:center; }
    &-right{ justify-content:flex-end; }
    .o-media-image, .o-media-body{ flex:1; }
}

.o-media-video{
    height:0; padding-bottom:56.25%; // 16:9
    position:relative;
    .o-media-iframe{ 
        width:100%; height:100%;
        position:absolute; top:0; left:0;
    }
}
.o-media-image{ 
    width:100%; 
    img{
        opacity:1;
        transition:opacity 1.5s ease-in;
        &.is-invisible{ opacity:0; }
    }
}
.o-media-body{ margin-top:1.5rem; }
.o-media-heading{ margin-bottom:1.5rem; }

/* 1024+ */
@include breakpoint(large) {

    .o-media-body{ width:90%; margin-top:2rem; }
    .o-media-heading{ margin-bottom:2rem; }

}