/**
 * Logo
 * @path: components/logo
 */
.c-logo-header{
    // padding-top:.5rem;
}
.c-logo{ 
    max-width:13rem; margin-right:2rem;
    vertical-align:top; 
}
.c-logo-payoff{ 
    max-width:10rem;
    vertical-align:top; 
    opacity:1;
    transition: opacity .3s ease-in-out .35s;
}

.c-logo-footer{
    .c-logo, .c-logo-payoff{ margin-bottom:rem-calc( get-v-spacing(small) * 2 ); }
}

/* 640px+ */
@include breakpoint(medium) {
    
    .c-logo{ max-width:14.5rem; }
    .c-logo-payoff{ max-width:11.2rem; }

    .c-logo-footer{
        .c-logo, .c-logo-payoff{ margin-bottom:0; }
    }

}