@charset 'UTF-8';

@font-face {
    font-family: 'dezwartehond';
    src:url('../fonts/icons/dezwartehond.eot');
    src:url('../fonts/icons/dezwartehond.eot?#iefix') format('embedded-opentype'),
        url('../fonts/icons/dezwartehond.woff') format('woff'),
        url('../fonts/icons/dezwartehond.ttf') format('truetype'),
        url('../fonts/icons/dezwartehond.svg#dezwartehond') format('svg');
    font-weight: normal;
    font-style: normal;
}

%icons{
    vertical-align:middle; 
    line-height: 1; 
    font-family: 'dezwartehond' !important; 
    font-style: normal !important; 
    font-weight: normal !important; 
    font-variant: normal !important; 
    text-transform: none !important;
    speak: none;    
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]::before,
[class*=" icon-"]::before {
    @extend %icons;
}
.icon-sort-desc::before{ content: '\63'; }
.icon-sort-asc::before{ content: '\64'; }
.icon-sort::before{ content: '\65'; }
.icon-arrow-65::before{ content: '\68'; }
.icon-arrow-66::before{ content: '\69'; }
.icon-arrow-67::before{ content: '\6a'; }
.icon-arrow-68::before{ content: '\6b'; }
.icon-cross::before{ content: '\66'; }