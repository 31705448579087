/**
 * Filter
 * @path: components/filter
 */

.c-filter{ 
    width:100%; height:4rem; margin-bottom:.5rem;
    background-color:scale-color( $body-background, $lightness:-5% );
    overflow:hidden;
    transition:height .4s ease-in-out;
}

.c-filter-head{ 
    width:100%; height:4rem; padding:0 1.3rem;
    position:relative; z-index:1;
    line-height:4.1rem; cursor:pointer;
    &::after{ 
        @extend %icons;
        position:absolute; z-index:5; top:50%; right:1.5rem;
        font-size:1rem; color:$body-font-color;
        content: '\68';
        transition:transform .3s ease-in-out;
        transform:translateY(-50%) rotateZ(0deg); 
    }
    &.is-down{
        &::after{
            transform:translateY(-50%) rotateZ(180deg);
        }
    }
}

.c-filter-item{
    padding:.35rem 1.3rem;
    line-height:1.25;
}

/* 1024+ */
@include breakpoint(large) {

    .c-filter-wrapper{
        padding-left:get-gutter(small);
        .column{ margin:0; }
    }

    .c-filter{ 
        height:auto; padding:0;
        background-color:transparent; 
    }

    .c-filter-head{ 
        height:auto; margin-bottom:2rem; padding:0;
        cursor:auto; line-height:inherit;
        display:block;
        &::after{ content:''; }
    }

    .c-filter-item{ padding:.35rem 0; }

}

/* 1200+ */
@include breakpoint(xlarge) {

    .c-filter-wrapper{ padding-left:0; }
    
}