/**
 * CSS Loader
 * @path: components/loader
 */
@keyframes loader {
    0% { transform:scale(0); }
    100% { 
        transform:scale(1.0);
        opacity:0; 
    }
}

.c-loader{
    @extend .u-margin-vertical;
    width:4rem; height:4rem; margin-left:auto; margin-right:auto;
    background-color:$primary-color; border-radius:100%;
    display:block;
    animation:loader 1s infinite ease-in-out
}