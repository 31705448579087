/**
 * Sass helper functions
 * @path: tools/functions
 */

/**
 * Get the value of the gutter per breakpoint and divide it by 2 
 *
 * @author Rianne Oosthoek
 * @param  {string} $name name of the breakpoint
 * @return {string} value for gutter
 */
@function get-gutter( $name, $half: true ) {
    @if $half {
        @return -zf-get-bp-val($grid-column-gutter, $name) / 2;
    } @else {
        @return -zf-get-bp-val($grid-column-gutter, $name);
    }
}

/**
 * Get the value of the v-spacing per breakpoint and divide it by 2 
 *
 * @author Rianne Oosthoek
 * @param  {string} $name name of the breakpoint
 * @return {string} value for v-spacing
 */
@function get-v-spacing( $name ) {
    @return -zf-get-bp-val($v-spacing-sizes, $name);
}