/**
 * List
 * @path: components/list
 */

.c-list-unstyled{ 
    margin-left:0;
    list-style-type:none;
}

@for $i from 1 to 4 {
    .c-list-#{$i}{ column-count:$i; column-gap:7rem; }
}
@include -zf-each-breakpoint {
    @for $i from 1 to 4 {
        .c-list-#{$-zf-size}-#{$i}{ 
            margin-top:2rem;
            column-count:$i; column-gap:7rem; 
        }
    }
}
.c-list-item{ margin-top:1.5rem; }

.c-project-filter-list{
    .c-list:last-child{ margin-bottom:0; }
}