/**
 * Slick Carousel
 * @path: components/slick
 */

.c-slider-wrapper{
    width:100%;
    position:relative; z-index:1;
    overflow:hidden;
    user-select: none;
    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    & > .c-slide{ margin:0 get-gutter(small); }
    .slick-list{
        width:100%;
        overflow: hidden;
        transform: translate3d(0, 0, 0);
    }
    .slick-track{ @include clearfix; }
    .slick-arrow{ 
        width:5rem; height:5rem;
        position:absolute; z-index:5; top:38%;
        background-color:$body-background;
        cursor:pointer;
        transform:translateY(-38%);
        [class*="icon-arrow"]{ font-size:1.8rem; color:$body-font-color; }
        &.slick-prev{ left:0; }
        &.slick-next{ right:0; }
    }
}
.c-slide{
    width:100%; margin:0 ( get-gutter(small) / 2 );
    float:left;
    &:focus{ outline:none; }
    a:focus{ outline:none; }
}

/* 640+ */
@include breakpoint(medium) {
    .c-slider-wrapper{ 
        padding-left:get-gutter(small) / 2; padding-right:get-gutter(small) / 2; 
        .c-slide{ 
            width:calc( ( 100% / 3 ) - 20px );
            margin:0 ( get-gutter(small) / 2 ); 
        }
    }
}

@include breakpoint(large) {
    .c-slider-wrapper{
        .c-slide{ width:calc( ( 100% / 4 ) - 20px ); }
        .slick-arrow{ 
            top:40%;
            transform:translateY(-40%);
        }
    }
}