/**
 * Foundation checkbox switch
 * @path: components/switch
 */

.switch-active, .switch-inactive{
    width:55%;
    text-align:center;
    display:inline-block;
}
.switch-active{left:0;}
.switch-inactive{right:0;}