/**
 * Page
 * @path: elements/page
 */

body{ 
    opacity:1;
    transition:background-color .3s ease-in-out, opacity .3s ease-in .2s; 
    &.is-invisible{ 
        opacity:0; 
        transition:background-color .3s ease-in-out, opacity .3s ease-in;
    }
}