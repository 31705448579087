/**
 * Custom grid
 * Path: objects/grid
 */

/**
 * Custom flex grid
 */
// Iterate through breakpoints defined in '$breakpoint-classes' (settings/foundation.scss).
@include -zf-each-breakpoint {
    /**
     * Custom grid for home, vision and procedure pages
     */
    // Iterate through '$o-grid' (settings/custom)
    @each $size, $styles in $o-grid {
        // Print css when breakpoint class of '$size' is equal to breakpoint class of $-zf-size
        @if $size == $-zf-size {
            // Loop through the styles per breakpoint
            @each $class, $style-defs in $styles {
                // .o-grid-#{$breakpoint}
                #{$class} {
                    .o-grid-cell{
                        // Is grid-size defined?
                        @if map-has-key( $style-defs, grid-size ) {
                            @include flex-grid-size( map-get( $style-defs, grid-size ) );
                        }

                        // Do we need to collapse the columns?
                        @if map-get( $style-defs, collapse ) == true{
                            // If so, collapse
                            @include grid-col-collapse;
                            .o-media-body{ padding-left:get-gutter(small); padding-right:get-gutter(small); }
                        } @else if {
                            // If not, use gutter size of current breakpoint (defined in settings/foundation)
                            @include grid-col-gutter($-zf-size);
                            .o-media-body{ padding-left:0; }
                        }
                    }
                }
            }
        }
    }

    /**
     * Custom block-grid for lists that have different amount of columns per breakpoint
     */
    // Create block-grid classes per breakpoint
    @include block-grid-html-classes($-zf-size,$include-spacing:false);
}

[class*="o-block-grid"] { 
    @include block-grid; 
    .o-column{
        .o-media-body{ padding-left:get-gutter(small); padding-right:get-gutter(small); }
    }
}

/** 
 * Normal flex grid
 */
.o-grid{ 
    display:flex;
    &.direction-column{ flex-direction:column; }
    &.space-evenly{ justify-content:space-evenly; }
    &.align-center{ align-items:center; }
    .o-grid-cell{
        flex:1 1 auto;
        &.no-flex{ flex:0 0 auto; }
    }
}
.o-grid-full-height{ height:100%; }

/* 1024+ */
@include breakpoint(large) {
    [class*="o-block-grid-"] {
        .o-column{
            .o-media-body{ padding-left:0; padding-right:0; }
        }
    }
}

/* 1200+ */
@include breakpoint(xlarge) {

    // Custom grid for home, vision and procedure pages
    .o-grid-small{
        &.align-left{ 
            .o-grid-cell{ margin-left:percentage(1 / $grid-column-count); }
        }
        &.align-right{ 
            .o-grid-cell{ margin-right:percentage(1 / $grid-column-count); }
        }
    } 
}