/**
 * Menu
 * @path: components/menu
 */

[data-overlay="menu"]{
    &.o-overlay{ 
        background-color:$primary-color; 
        a{ color:$white; }
        .c-link{ border-color:$white; }
    }
}

/* Ensure readability of menu on landscape mobile devices */
@media screen and (max-height: 640px) and (orientation: landscape) {
    .c-menu{ 
        padding-top:2.5rem;
        &.o-grid-full-height{ height:auto; }
    }
}