/**
 * Overlay
 * @path: objects/overlay
 */

.o-overlay{
    position:fixed; z-index:1050; top:0; right:0; bottom:0; left:0;
    background-color:$body-background;
    overflow-y:scroll; -webkit-overflow-scrolling:touch;
    transition:background-color .2s ease-in-out;
}

.o-overlay-content{ height:calc(100% - 5.5rem); padding-bottom:5rem; }

.o-overlay-open{  
    width:100%;
    position:fixed; z-index:1;
    overflow:hidden; 
}

/* 1024+ */
@include breakpoint(large) {
    .o-overlay-content{ height:calc(100% - 7rem); }    
}